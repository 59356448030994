body {
  margin: 0;
  font-family: 'Read', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.slide-appear,
.slide-enter {
  opacity: 0;
  transform: translateX(-250px);
}
.slide-appear.slide-appear-active,
.slide-enter.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: 0.5s;
}

.slide-leave {
  opacity: 1;
  transform: translateX(0);
}

.slide-leave.slide-leave-active {
  opacity: 0;
  transform: translateX(250px);
  transition: 0.5s;
}
