@font-face {
  font-family: 'DaciaSpirit';
  src: url('/static/fonts/DaciaSpirit/DaciaSpirit-Light.woff2') format('woff2'),
    url('/static/fonts/DaciaSpirit/DaciaSpirit-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DaciaSpirit';
  src: url('/static/fonts/DaciaSpirit/DaciaSpirit-Regular.woff2')
      format('woff2'),
    url('/static/fonts/DaciaSpirit/DaciaSpirit-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DaciaSpirit';
  src: url('/static/fonts/DaciaSpirit/DaciaSpirit-Black.woff2') format('woff2'),
    url('/static/fonts/DaciaSpirit/DaciaSpirit-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'DaciaSpirit';
  src: url('/static/fonts/DaciaSpirit/DaciaSpirit-Bold.woff2') format('woff2'),
    url('/static/fonts/DaciaSpirit/DaciaSpirit-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DaciaSpirit';
  src: url('/static/fonts/DaciaSpirit/DaciaSpirit-Black.woff2') format('woff2'),
    url('/public/static/fonts/DaciaSpirit/DaciaSpirit-Black.woff')
      format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Read';
  src: url('/static/fonts/Read/Read-Light_V1100.woff2') format('woff2'),
    url('/static/fonts/Read/Read-Light_V1100.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Read';
  src: url('/static/fonts/Read/Read-LightItalic_V1100.woff2') format('woff2'),
    url('/static/fonts/Read/Read-LightItalic_V2000.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Read';
  src: url('/static/fonts/Read/Read-Italic_V2000.woff2') format('woff2'),
    url('/static/fonts/Read/Read-Italic_V2000.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Read';
  src: url('/static/fonts/Read/Read-Regular_V1100.woff2') format('woff2'),
    url('/static/fonts/Read/Read-Regular_V1100.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Read';
  src: url('/static/fonts/Read/Read-Medium_V2000.woff2') format('woff2'),
    url('/static/fonts/Read/Read-Medium_V2000.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Read';
  src: url('/static/fonts/Read/Read-MediumItalic_V2000.woff2') format('woff2'),
    url('/static/fonts/Read/Read-MediumItalic_V2000.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Read';
  src: url('/static/fonts/Read/Read-Bold_V1100.woff2') format('woff2'),
    url('/static/fonts/Read/Read-Bold_V1100.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Read';
  src: url('/static/fonts/Read/Read-BoldItalic_V2000.woff2') format('woff2'),
    url('/static/fonts/Read/Read-BoldItalic_V2000.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
